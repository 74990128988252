import { useEffect, useState } from 'react'

function Auth (props) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  useEffect(() => {}, [username, password])
  const handleSignIn = ev => {
    ev.preventDefault();
    console.log(username, password)
    if (
      username.toLowerCase() == 'dior' &&
      password.toLowerCase() == 'paris2023!'
    ) {
      window.localStorage.setItem("auth_dioparis", true);
      window.location.reload();
    }
  }
  return (
    <div className='auth-form'>
      <form>
        <label htmlFor='diorparis-username'>Username</label>
        <input
          type='text'
          id='diorparis-username'
          value={username}
          onChange={ev => setUsername(ev.target.value)}
        />
        <br />
        <label htmlFor='diorparis-password'>Password</label>
        <input
          type='password'
          id='diorparis-password'
          value={password}
          onChange={ev => setPassword(ev.target.value)}
        />
        <br />
        <button onClick={ev => handleSignIn(ev)}>Sign In</button>
      </form>
    </div>
  )
}

export default Auth
