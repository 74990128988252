import { useEffect, useState } from 'react'

function ItemCards (props) {
  const assets = [
    {
      name: 'Saddle',
      thumbnail:
        'https://solutions-engineering.s3.amazonaws.com/dior-paris-2023/dior-paris-saddle.png',
      links: [
        {
          player: 'Current',
          thumbnail: '',
          link: 'https://tk-viewer.threek.it/?assetId=2a3874d2-613e-44de-a715-948d30bb95b9&authToken=ed53ff11-843f-4ac2-944f-1900e16bc9d9&environment=preview&showAR=true&showShare=true'
        },
        {
          player: 'New',
          link: 'https://threekit-react-three-pmimterxsa-uc.a.run.app/dior?asset=christian-dior'
        }
      ]
    },
    {
      name: 'Bag 2',
      thumbnail:
        'https://solutions-engineering.s3.amazonaws.com/dior-paris-2023/dior-paris-bag-e.png',
      links: [
        {
          player: 'Current',
          link: 'https://tk-viewer.threek.it/?assetId=3e355afc-24ca-463b-b7ea-63810eab384a&authToken=ed53ff11-843f-4ac2-944f-1900e16bc9d9&environment=preview&showAR=true&showShare=true'
        },
        {
          player: 'New',
          link: 'https://threekit-react-three-pmimterxsa-uc.a.run.app/dior?asset=lady-dior'
        }
      ]
    },
    {
      name: 'Ring',
      thumbnail:
        'https://solutions-engineering.s3.amazonaws.com/dior-paris-2023/dior-paris-ring.png',

      links: [
        {
          player: 'Current',
          link: 'https://tk-viewer.threek.it/?assetId=0e1d0a59-daa8-4fd2-8e4f-efa07e5d220e&authToken=ed53ff11-843f-4ac2-944f-1900e16bc9d9&environment=preview&showAR=true&showShare=true'
        },
        {
          player: 'New',
          link: 'https://threekit-react-three-pmimterxsa-uc.a.run.app/dior?asset=dior-revolution-ring'
        },
        {
          player: 'VTO',
          link: '#'
        }
      ]
    },
    {
      name: 'Glasses',
      thumbnail:
        'https://solutions-engineering.s3.amazonaws.com/dior-paris-2023/dior-paris-glasses.png',

      links: [
        {
          player: 'Current',
          link: 'https://tk-viewer.threek.it/?assetId=cb1844e6-af4c-478f-965d-d86ef456d80f&authToken=ed53ff11-843f-4ac2-944f-1900e16bc9d9&environment=preview&showAR=true&showShare=true'
        },
        {
          player: 'New',
          link: 'https://threekit-react-three-pmimterxsa-uc.a.run.app/dior?asset=miss-dior-sunglasses'
        },
        {
          player: 'VTO',
          link: 'https://threekit-react-three-pmimterxsa-uc.a.run.app/vto-face?asset=miss-dior-sunglasses'
        }
      ]
    }
  ]

  useEffect(() => {}, [])

  return (
    <div className='auth-form'>
      <div className='card-container'>
        {assets.map(asset => {
          return (
            <div key={asset.name} className='card'>
              <img src={asset.thumbnail} style={{ maxHeight: '250px' }} />
              <center>{asset.name}</center>
              <div className='asset-link-list'>
                <ul>
                  {asset.links.map(link => {
                    return (
                      <li>
                        <a href={link.link} target='_blank'>
                          {link.player}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ItemCards
