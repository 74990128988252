import { useEffect, useState } from 'react'
import Auth from './components/Auth'
import ItemCards from './components/ItemCards'
import './App.css'

function App () {
  const loggedIn = localStorage.getItem('auth_dioparis')
  useEffect(() => {
    console.log(loggedIn)
  }, [loggedIn])
  return (
    <div className='App'>
      <h1>Dior Paris</h1>
      {loggedIn ? <ItemCards /> : <Auth />}
      {loggedIn ? (
        <button
          onClick={() => {
            window.localStorage.removeItem('auth_dioparis')
            window.location.reload()
          }}
        >
          Sign Out
        </button>
      ) : null}
    </div>
  )
}

export default App
